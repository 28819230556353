// @flow
import React from 'react';

import SubscriptionStatus from './SubscriptionStatus';

const SubscriptionsTab = (): React$Node => (
  <div className="account-tab">
    <SubscriptionStatus />
  </div>
);

export default SubscriptionsTab;
