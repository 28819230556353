// @flow
import './index.less';

import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';

import BasicSpinner from '_components/BasicSpinner';

type Props = {
  label: string,
  error?: ?Object,
  loading?: boolean,
  disabled?: boolean,
  value: ?string,
  placeholder?: string,
  onChange?: (date: Object, dateString: string) => void,
};

const LabelDatePicker = ({
  label,
  error,
  loading = false,
  disabled = false,
  value,
  placeholder,
  onChange,
}: Props): React$Node => {
  const errorMsg = useMemo(() => error?.message, [error]);

  return (
    <Form.Item
      className="label-datepicker"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label={label}
      help={errorMsg}
      validateStatus={errorMsg && 'error'}
    >
      <BasicSpinner loading={loading}>
        <DatePicker
          value={value ? moment(value) : null}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          format="DD/MM/YYYY"
        />
      </BasicSpinner>
    </Form.Item>
  );
};

export default LabelDatePicker;
