// @flow
import './index.less';

import { Radio } from 'antd';
import React, { useMemo } from 'react';

type Props = {
  options: $ReadOnlyArray<{
    label: string,
    value: any,
  }>,
  value: any,
  onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  loading?: boolean,
};

const RadioGroup = ({ options, value, onChange, loading = false }: Props): React$Node => {
  const radioElems = useMemo(
    () =>
      options.map((opt, idx) => (
        <Radio key={`radio-${idx}`} value={opt.value}>
          {opt.label}
        </Radio>
      )),
    [options]
  );

  return (
    <Radio.Group className="radio-group" onChange={onChange} value={value} disabled={loading}>
      {radioElems}
    </Radio.Group>
  );
};

export default RadioGroup;
