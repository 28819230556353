// @flow
import './index.less';

import React, { useMemo } from 'react';

import FlexBlock from '_components/FlexBlock';

type Props = {
  max: number,
  count: number,
};

const Dots = ({ max, count }: Props): React$Node => {
  const dotElems = useMemo(
    () =>
      Array(max)
        .fill()
        .map((_, i) => {
          const bgColor = i > count - 1 ? '#4f1219' : '#d0021c';
          return (
            <span
              key={`dot-${i}`}
              className="dot"
              style={{
                backgroundColor: bgColor,
              }}
            />
          );
        }),
    [max, count]
  );

  return (
    <FlexBlock className="dots" align="center">
      {dotElems}
      {count > max && <span className="extra-count">+{count - max}</span>}
    </FlexBlock>
  );
};

export default Dots;
