// @flow
import moment from 'moment';
import { useEffect, useState } from 'react';

import { countWorkoutHistories, getFirstCompletedWorkoutHistory, getWorkoutHistories } from '_utils/workoutHistories';
import type { WorkoutHistory } from '_utils/workoutHistories/types';

type WorkoutHistoryData = {
  loading: boolean,
  error: ?Error,
  workoutHistories: $ReadOnlyArray<WorkoutHistory>,
  firstWorkoutHistory: ?WorkoutHistory,
  count: number,
};

const useWorkoutHistories = (): WorkoutHistoryData => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    workoutHistories: [],
    firstWorkoutHistory: null,
    count: 0,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const workoutHistories = await getWorkoutHistories({
          startTime: moment().subtract(1, 'month').startOf('month').toDate(),
          endTime: moment().add(1, 'month').startOf('month').toDate(),
        });
        const firstWorkoutHistory = await getFirstCompletedWorkoutHistory();
        const count = await countWorkoutHistories();

        setData({
          workoutHistories,
          firstWorkoutHistory,
          count,
        });
        setError(null);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, error, ...data };
};

export default useWorkoutHistories;
