// @flow
import './SubscriptionItem.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';

import PromotionCode from '_components/CheckoutSection/PromotionCode';
import FlexBlock from '_components/FlexBlock';
import { CheckoutProvider } from '_contexts/checkout';
import LogoImage from '_images/logo.png';
import { AppDateFormat } from '_utils/date';

type Props = {
  planType?: 'annually' | 'quarterly' | 'monthly',
  incomplete?: boolean,
  trial?: boolean,
  unsubscribed?: boolean,
  freeMemberAccess?: boolean,
  startDate: Date,
  expiryDate: Date,
  nextInvoicePayableAmount?: ?string,
};

const SubscriptionItem = ({
  planType,
  incomplete = false,
  trial = false,
  unsubscribed = false,
  freeMemberAccess = false,
  startDate,
  expiryDate,
  nextInvoicePayableAmount,
}: Props): React$Node => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (freeMemberAccess) {
      return 'Ritual Gym Member Access';
    }

    switch (planType) {
      case 'annually':
        return t('Purchase_PackageAnnual');

      case 'quarterly':
        return t('Purchase_PackageQuarterly');

      case 'monthly':
        return t('Purchase_PackageMonthly');

      default:
        throw new Error('unknown plan type');
    }
  }, [t, freeMemberAccess, planType]);

  const description = useMemo(() => {
    if (incomplete) {
      return '';
    }
    const formattedStartDate = moment(startDate).format(AppDateFormat);
    const formattedExpiryDate = moment(expiryDate).format(AppDateFormat);

    const lines = [t('SubscriptionSection_SubscriptionStartedOn', { formattedStartDate })];

    if (freeMemberAccess || unsubscribed) {
      lines.push(`Will end on ${formattedExpiryDate}`);
      lines.push(t('SubscriptionSection_SubscriptionEndsOn', { formattedExpiryDate }));
    } else if (trial) {
      lines.push(t('SubscriptionSection_FirstPaymentDueOn', { formattedExpiryDate }));
    } else if (nextInvoicePayableAmount) {
      lines.push(t('SubscriptionSection_NextPaymentDueOn', { formattedExpiryDate, nextInvoicePayableAmount }));
    }

    return lines.join('\n\n');
  }, [t, startDate, expiryDate, freeMemberAccess, trial, unsubscribed, nextInvoicePayableAmount]);

  return (
    <>
      <FlexBlock className="subscription-item" align="center">
        <div className="logo">
          <img src={LogoImage} alt="Ritual FIT" />
        </div>
        <FlexBlock className="content" vertical>
          <div className="title">{title}</div>
          <Markdown className="description">{description}</Markdown>
        </FlexBlock>
      </FlexBlock>
      {!unsubscribed && !freeMemberAccess && (
        <div>
          <CheckoutProvider>
            <PromotionCode type="redeem" />
          </CheckoutProvider>
        </div>
      )}
    </>
  );
};

export default SubscriptionItem;
