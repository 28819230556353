// @flow
import './index.less';

import { Form, Input } from 'antd';
import React, { useMemo } from 'react';

import BasicSpinner from '_components/BasicSpinner';

type Props = {
  type?: 'text' | 'password',
  label: string,
  name: string,
  error?: ?Object,
  loading?: boolean,
  disabled?: boolean,
  value: ?string,
  placeholder?: string,
  prefix?: React$Node,
  suffix?: React$Node,
  onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur?: () => void,
};

const LabelInput = ({
  type = 'text',
  label,
  name,
  error,
  loading = false,
  disabled = false,
  value,
  placeholder,
  prefix,
  suffix,
  onChange,
  onBlur,
}: Props): React$Node => {
  const errorMsg = useMemo(() => error?.message, [error]);

  return (
    <Form.Item
      className="label-input"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label={label}
      help={errorMsg}
      validateStatus={errorMsg && 'error'}
    >
      <BasicSpinner loading={loading}>
        <Input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          onChange={onChange}
          onBlur={onBlur}
        />
      </BasicSpinner>
    </Form.Item>
  );
};

export default LabelInput;
