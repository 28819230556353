// @flow
import './Card.less';

import { Divider } from 'antd';
import React from 'react';

type Props = {
  title?: React$Node,
  children?: React$Node,
};

const Card = ({ title, children }: Props): React$Node => (
  <div className="card">
    {title && <div className="title">{title}</div>}
    {title && <Divider className="divider" />}
    {children}
  </div>
);

export default Card;
