// @flow
import { LockOutlined } from '@ant-design/icons';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import LabelBlock from '_components/LabelBlock';
import LabelInput from '_components/LabelInput';
import { useAuthState } from '_contexts/auth';

const AccountTab = (): React$Node => {
  const { t } = useTranslation();

  const { loading, currentUser } = useAuthState();

  return (
    <div className="account-tab">
      <LabelBlock label="Email">
        <LabelInput
          name="email"
          label={t('Label_EmailAddress')}
          value={currentUser?.email}
          loading={loading}
          disabled
          suffix={<LockOutlined />}
        />
      </LabelBlock>
    </div>
  );
};

export default AccountTab;
