// @flow
import './Header.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import React, { useMemo } from 'react';

import FlexBlock from '_components/FlexBlock';
import ProfileAvatar from '_components/ProfileAvatar';
import WorkoutStatistic from '_components/ProfileSection/WorkoutStatistic';
import { useAuthState } from '_contexts/auth';
import useBreakpoint from '_hooks/useBreakpoint';
import { AppDateFormat } from '_utils/date';

const ProfileHeader = (): React$Node => {
  const { t } = useTranslation();

  const isDesktop = useBreakpoint({ min: 'lg' });

  const { currentUser } = useAuthState();

  const avatarUrl = useMemo(() => currentUser?.avatarURL, [currentUser]);
  const avatarName = useMemo(() => {
    if (!currentUser) return;

    const { knownAs } = currentUser;

    return knownAs;
  }, [currentUser]);

  if (!currentUser) return null;

  return (
    <FlexBlock className="profile-header" align="center" vertical={!isDesktop}>
      <ProfileAvatar src={avatarUrl} name={avatarName} size="lg" />
      <FlexBlock className="summary" vertical={isDesktop} flex={1}>
        <FlexBlock vertical={!isDesktop} align={!isDesktop ? 'center' : undefined}>
          <FlexBlock vertical flex={1} style={isDesktop ? { marginTop: -5 } : { marginBottom: 20 }}>
            <div className="email">{currentUser.email}</div>
            <div className="member-status">
              {t('ProfileSection_MemberSince', { date: moment(currentUser.createdAt).format(AppDateFormat) })}
            </div>
          </FlexBlock>
          <WorkoutStatistic />
        </FlexBlock>
      </FlexBlock>
    </FlexBlock>
  );
};

export default ProfileHeader;
