// @flow
import './index.less';

import { Tabs } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Section from '_components/Section';
import useBreakpoint from '_hooks/useBreakpoint';

import AccountTab from './AccountTab';
import Card from './Card';
import ProfileHeader from './Header';
import PersonalInfoTab from './PersonalInfoTab';
import SubscriptionsTab from './SubscriptionsTab';

const { TabPane } = Tabs;

const ProfileSection = (): React$Node => {
  const { t } = useTranslation();

  const isDesktop = useBreakpoint({ min: 'lg' });

  return (
    <Section>
      <div className="profile-container">
        <div className="center-container">
          <ProfileHeader />
          <Tabs className="tabs" tabPosition={isDesktop ? 'left' : 'top'}>
            <TabPane tab={t('ProfileSection_TabProfile')} key="1">
              <Card title={t('ProfileSection_TabProfile_Title')}>
                <PersonalInfoTab />
              </Card>
            </TabPane>
            <TabPane tab={t('ProfileSection_TabAccount')} key="2">
              <Card title={t('ProfileSection_TabAccount_Title')}>
                <AccountTab />
              </Card>
            </TabPane>
            <TabPane tab={t('ProfileSection_TabSubscriptions')} key="3">
              <Card title={t('ProfileSection_TabSubscriptions_Title')}>
                <SubscriptionsTab />
              </Card>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Section>
  );
};

export default ProfileSection;
