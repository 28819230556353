// @flow
import './SubscriptionStatus.less';

import { Button as AntDButton, Divider, message, Skeleton } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import { v4 as uuidv4 } from 'uuid';

import BasicSpinner from '_components/BasicSpinner';
import Button from '_components/Button';
import FlexBlock from '_components/FlexBlock';
import { useAuth, useAuthState } from '_contexts/auth';
import { AppDateFormat, DbDateFormat } from '_utils/date';
import { cancelSubscription } from '_utils/stripe';

import SubscriptionItem from './SubscriptionItem';
import PaymentManager from '_components/PaymentManager';
import SupportCardLogos from '_components/SupportCardLogos';
import { checkHasExpiredSubscription } from '_utils/subscription';

const SubsciprionStatus = (): React$Node => {
  const { t } = useTranslation();

  const { loading, currentUser, restoring } = useAuthState();
  const { updateProfile: updateProfileContext, restore } = useAuth();

  useEffect(() => {
    restore();
  }, [restore]);

  const [description, actionElem] = useMemo(() => {
    if (currentUser?.entitlement) {
      const { entitlement, stripeCustomer } = currentUser;
      const { nextInvoice } = stripeCustomer;

      const startDate = moment(entitlement.purchaseDate, DbDateFormat);
      const expiryDate = moment(entitlement.expiresDate, DbDateFormat);
      const formattedExpireDate = expiryDate.format(AppDateFormat);
      const planType = entitlement.planType.toLowerCase();
      const isTrial = entitlement.periodType === 'TRIAL';
      const isIncomplete = entitlement.status === 'INCOMPLETE';
      const isUnsubscribed = entitlement.status === 'CANCELED';
      const isCanceled = !nextInvoice;

      const subscriptionItemElem = (
        <SubscriptionItem
          incomplete={isIncomplete}
          trial={isTrial}
          unsubscribed={isUnsubscribed}
          planType={planType}
          startDate={startDate}
          expiryDate={expiryDate}
          nextInvoicePayableAmount={nextInvoice ? `$${nextInvoice.amountDue / 100}` : null}
        />
      );

      if (entitlement.store !== 'STRIPE') {
        return [t('SubscriptionStatus_Description_AppStoreMember'), null];
      }

      if (isUnsubscribed) {
        if (moment().isAfter(expiryDate)) {
          return [
            t('SubscriptionStatus_Description_Expired', { date: formattedExpireDate }),
            <Button style={{ marginTop: 16, width: '100%' }} to="/checkout">
              {t('Common_BuyNow')}
            </Button>,
          ];
        }

        return [
          t('SubscriptionStatus_Description_CancelledButExpiring', { date: formattedExpireDate }),
          subscriptionItemElem,
        ];
      }

      if (isIncomplete) {
        return [t('SubscriptionStatus_Description_Incomplete'), subscriptionItemElem];
      }

      if (isTrial) {
        return [t('SubscriptionStatus_Description_ActiveTrial', { date: formattedExpireDate }), subscriptionItemElem];
      }

      if (isCanceled) {
        return [
          t('SubscriptionStatus_Description_ActiveCanceled', { date: formattedExpireDate }),
          subscriptionItemElem,
        ];
      }

      return [t('SubscriptionStatus_Description_Active', { date: formattedExpireDate }), subscriptionItemElem];
    }

    if (currentUser?.membership?.freeAppAccess) {
      const startDate = moment(currentUser.membership.startDate, DbDateFormat);
      const expiryDate = moment(currentUser.membership.endDate, DbDateFormat);
      const formattedExpireDate = expiryDate.format(AppDateFormat);
      const subscriptionItemElem = <SubscriptionItem freeMemberAccess startDate={startDate} expiryDate={expiryDate} />;
      return [t('SubscriptionStatus_Description_OWComplimentary', { date: formattedExpireDate }), subscriptionItemElem];
    }

    if (checkHasExpiredSubscription(currentUser)) {
      return (
        <Button style={{ marginTop: 16, width: '100%' }} to="/checkout">
          {t('Common_BuyNow')}
        </Button>
      );
    }

    return [
      t('SubscriptionStatus_Description_NoSubscription'),
      <Button style={{ marginTop: 16, width: '100%' }} to="/checkout">
        {t('Common_StartFreeTrial')}
      </Button>,
    ];
  }, [t, currentUser]);

  const cancellable = useMemo(() => {
    if (!currentUser?.entitlement) return false;
    if (!currentUser?.stripeCustomer?.nextInvoice) return false;

    return currentUser.entitlement.status !== 'CANCELED';
  }, [currentUser]);

  const [cancelling, setCancelling] = useState(false);

  const handleCancelSubscription = useCallback(() => {
    if (!currentUser?.entitlement?.subscriptionId) return;

    (async () => {
      setCancelling(true);
      try {
        await cancelSubscription(uuidv4(), currentUser.entitlement.subscriptionId);
        restore()
      } catch (err) {
        message.error(t('Error_CancelSubscription'));
      }
      setCancelling(false);
    })();
  }, [t, currentUser, updateProfileContext]);

  if (restoring) {
    return (
      <div className="subscription-status">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="subscription-status">
      <div>
        <FlexBlock align="center">
          <div className="title">{t('SubscriptionStatus_Title')}</div>
          {cancellable && (
            <AntDButton type="link" className="action-btn" onClick={handleCancelSubscription} loading={cancelling}>
              {t('Common_Cancel')}
            </AntDButton>
          )}
        </FlexBlock>

        <BasicSpinner loading={loading}>
          <Markdown className="description">{description}</Markdown>
          {actionElem}
        </BasicSpinner>
      </div>
      <Divider className="divider" />

      <div className="payment-info">
        <div className="title">
          {t('PaymentMethods_Title')}
          <SupportCardLogos />
        </div>
        <div className="note">{t('SubscriptionStatus_SubscriptionOnMobileNote')}</div>
        <PaymentManager active={true} editable={true} backgroundColor={'#222222'} />
      </div>
    </div>
  );
};

export default SubsciprionStatus;
