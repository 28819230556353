// @flow
import React, { useMemo } from 'react';

type Props = {
  className?: string,
  style?: Object,
  children: React$Node,
  vertical?: boolean,
  align?: 'start' | 'center' | 'end' | 'stretch',
  flex?: number,
};

const FlexBlock = ({ className, style, children, vertical = false, align = 'stretch', flex }: Props): React$Node => {
  const alignItems = useMemo(() => {
    switch (align) {
      case 'start':
        return 'flex-start';

      case 'center':
        return 'center';

      case 'end':
        return 'flex-end';

      default:
        return 'stretch';
    }
  }, [align]);

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: vertical ? 'column' : 'row',
        alignItems,
        flex,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default FlexBlock;
