// @flow
import './PersonalInfoTab.less';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button, message } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import LabelBlock from '_components/LabelBlock';
import LabelDatePicker from '_components/LabelDatePicker';
import LabelInput from '_components/LabelInput';
import RadioGroup from '_components/RadioGroup';
import { useAuth, useAuthState } from '_contexts/auth';
import { DbDateFormat } from '_utils/date';
import { updateProfile } from '_utils/profile';

const validationSchema = yup.object().shape({
  knownAs: yup.string().required(),
});

const PersonalInfoTab = (): React$Node => {
  const { t } = useTranslation();

  const { loading, currentUser } = useAuthState();
  const { updateProfile: updateProfileContext } = useAuth();

  const genderOptions = useMemo(
    () => [
      { label: t('Common_Male'), value: 'MALE' },
      { label: t('Common_Female'), value: 'FEMALE' },
      { label: t('Common_Other'), value: 'OTHER' },
    ],
    [t]
  );

  const { handleSubmit, control, errors, formState, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [updating, setUpdating] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      (async () => {
        setUpdating(true);
        try {
          const profile = await updateProfile(values);
          updateProfileContext(profile);
          reset(values, { isDirty: false });
        } catch (err) {
          message.error(err?.message);
        }
        setUpdating(false);
      })();
    },
    [updateProfileContext, reset]
  );

  const submitDisabled = useMemo(() => {
    const { isDirty, isValid } = formState;
    return loading || !isDirty || !isValid;
  }, [loading, formState]);

  return (
    <form className="personal-info-tab" onSubmit={handleSubmit(onSubmit)}>
      <LabelBlock label={t('Label_Name')}>
        <Controller
          name="knownAs"
          control={control}
          defaultValue={currentUser?.knownAs}
          render={({ onChange, value }) => (
            <LabelInput
              name="knownAs"
              label={t('Label_YourName')}
              error={errors.knownAs}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              loading={loading}
            />
          )}
        />
      </LabelBlock>
      <LabelBlock label={t('Label_Gender')}>
        <Controller
          name="gender"
          control={control}
          defaultValue={currentUser?.gender}
          render={({ onChange, value }) => (
            <RadioGroup
              options={genderOptions}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              loading={loading}
            />
          )}
        />
      </LabelBlock>
      <LabelBlock label={t('Label_Birthday')}>
        <Controller
          name="birthday"
          control={control}
          defaultValue={currentUser?.birthday}
          render={({ onChange, value }) => (
            <LabelDatePicker
              label={t('Label_DateOfBirth')}
              error={errors.birthday}
              value={value}
              onChange={(date) => onChange(date.format(DbDateFormat))}
              loading={loading}
            />
          )}
        />
      </LabelBlock>
      <Button
        className="submit-btn"
        type="primary"
        htmlType="submit"
        loading={updating}
        block
        disabled={submitDisabled}
      >
        {t('Common_SaveChanges')}
      </Button>
    </form>
  );
};

export default PersonalInfoTab;
