// @flow
import './WorkoutStatistic.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import lodash from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import BasicSpinner from '_components/BasicSpinner';
import Dots from '_components/Dots';
import FlexBlock from '_components/FlexBlock';
import { useAuthState } from '_contexts/auth';
import useWorkoutHistories from '_hooks/useWorkoutHistories';

const WorkoutStatistic = (): React$Node => {
  const { t } = useTranslation();

  const { currentUser } = useAuthState();

  const { loading, workoutHistories, firstWorkoutHistory, count: workoutHistoriesCount } = useWorkoutHistories();

  const weeklySessions = useMemo(() => {
    const fromDate = moment().startOf('isoWeek').toDate();
    const toDate = moment().endOf('isoWeek').toDate();
    return lodash.sumBy(lodash.values(workoutHistories), (wh) => {
      const completedTime = new Date(wh.completedAt);
      return fromDate < completedTime && completedTime < toDate ? 1 : 0;
    });
  }, [workoutHistories]);

  const weeklyAverage = useMemo(() => {
    if (workoutHistoriesCount === 0) return 0;
    const age = firstWorkoutHistory?.completedAt ? moment().diff(moment(firstWorkoutHistory.completedAt), 'days') : 0;
    const weeksSinceJoin = age / 7;
    if (age <= 7) {
      return workoutHistoriesCount;
    }
    const sessionsperweek = parseFloat((workoutHistoriesCount / weeksSinceJoin).toFixed(1));

    return sessionsperweek;
  }, [workoutHistoriesCount, firstWorkoutHistory]);

  return (
    <FlexBlock className="workout-statistic">
      <FlexBlock className="panel" vertical align="center">
        <div className="value">
          <BasicSpinner loading={loading}>{workoutHistoriesCount.toString()}</BasicSpinner>
        </div>
        <div className="label">{t('ProfileSection_WorkoutStatistic_TotalSessions')}</div>
      </FlexBlock>
      <FlexBlock className="panel" vertical align="center">
        <BasicSpinner loading={loading}>
          <Dots max={currentUser?.fitnessProfile?.checkinGoal || 3} count={weeklySessions} />
        </BasicSpinner>
        <div className="label">{t('ProfileSection_WorkoutStatistic_ThisWeek')}</div>
      </FlexBlock>
      <FlexBlock className="panel" vertical align="center">
        <div className="value">
          <BasicSpinner loading={loading}>{weeklyAverage.toString()}</BasicSpinner>
        </div>
        <div className="label">{t('ProfileSection_WorkoutStatistic_WeeklyAverage')}</div>
      </FlexBlock>
    </FlexBlock>
  );
};

export default WorkoutStatistic;
