// @flow
import { gql } from 'graphql-request';

import { runQuery } from '_client';

import type { WorkoutHistoriesFilters, WorkoutHistory } from './types';

const GET_WORKOUT_HISTORIES = gql`
  query getWorkoutHistories(
    $first: Int
    $after: Datetime
    $startTime: Datetime
    $endTime: Datetime
    $programId: UID
    $order: Order!
  ) {
    me {
      workoutHistoriesV2(
        first: $first
        after: $after
        filters: { startTime: $startTime, endTime: $endTime, programId: $programId }
        orderBy: { field: COMPLETED_AT, order: $order }
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            userId
            programId
            completedAt
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

const GET_WORKOUT_HISTORIES_COUNT = gql`
  query getWorkoutHistories($startTime: Datetime, $endTime: Datetime, $programId: UID) {
    me {
      workoutHistoriesV2(filters: { startTime: $startTime, endTime: $endTime, programId: $programId }) {
        totalCount
      }
    }
  }
`;

export const countWorkoutHistories = async (filters?: WorkoutHistoriesFilters): Promise<number> => {
  const variables: Object = {};
  if (filters) {
    variables.startTime = filters.startTime;
    variables.endTime = filters.endTime;
    variables.programId = filters.programId;
  }

  const { me } = await runQuery(GET_WORKOUT_HISTORIES_COUNT, variables);

  return me?.workoutHistoriesV2?.totalCount || 0;
};

export const getWorkoutHistories = async (
  filters?: WorkoutHistoriesFilters,
  order?: 'ASC' | 'DESC' = 'DESC'
): Promise<$ReadOnlyArray<WorkoutHistory>> => {
  const workoutHistories = [];

  let after = null;
  let hasNextPage = true;
  while (hasNextPage) {
    const variables: Object = { after, order };
    if (filters) {
      variables.startTime = filters.startTime;
      variables.endTime = filters.endTime;
      variables.programId = filters.programId;
    }
    const { me } = await runQuery(GET_WORKOUT_HISTORIES, variables);
    if (!me?.workoutHistoriesV2) {
      break;
    }

    const { edges, pageInfo } = me.workoutHistoriesV2;
    workoutHistories.push(...edges.map((x) => x.node));
    hasNextPage = pageInfo.hasNextPage;
    after = pageInfo.endCursor;
  }

  return workoutHistories;
};

export const getFirstCompletedWorkoutHistory = async (): Promise<?WorkoutHistory> => {
  const { me } = await runQuery(GET_WORKOUT_HISTORIES, { first: 1, order: 'ASC' });
  if (!me?.workoutHistoriesV2) return null;

  const { edges } = me.workoutHistoriesV2;
  if (!edges || edges.length === 0) return null;

  return edges[0].node;
};
