// @flow
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import FullPageSpinner from '_components/FullPageSpinner';
import Layout from '_components/Layout';
import ProfileSection from '_components/ProfileSection';
import { useAuthState } from '_contexts/auth';
import useAnalytics, { event } from '_hooks/useAnalytics';

const Profile = (): React$Node => {
  const { track } = useAnalytics();

  const { loading, currentUser } = useAuthState();

  useEffect(() => {
    track({
      group: event.group.Profile,
      action: event.action.Viewed,
    });
  }, [track]);

  useEffect(() => {
    if (loading || currentUser) return;

    navigate('/login');
  }, [loading, currentUser]);

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Layout floatingNavbar={false} navbarTemplate="profile">
      <ProfileSection />
    </Layout>
  );
};

export default Profile;
