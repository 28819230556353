// @flow
import './index.less';

import React from 'react';

import FlexBlock from '_components/FlexBlock';

type Props = {
  label: string,
  children: React$Node,
};

const LabelBlock = ({ label, children }: Props): React$Node => (
  <FlexBlock className="label-block">
    <FlexBlock className="label">{label}</FlexBlock>
    <FlexBlock flex={1}>{children}</FlexBlock>
  </FlexBlock>
);

export default LabelBlock;
